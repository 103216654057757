
import {
	Clear,
	Refresh
} from '@mui/icons-material';
import {
	Button,
	IconButton,
	LinearProgress,
	Link
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {
	DataGridPremium,
	GridColDef,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton,
	useGridApiContext
} from '@mui/x-data-grid-premium';
import * as React from 'react';
import {
	useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { UserApi } from 'services/user-api';
import ProfileImage from 'components/ProfileImage';
import ContactCard from '../ContactCard';
import useLocalStorage from '../hooks/LocalStorage';
import PageTitle from '../hooks/PageTitle';
import { User } from './types';

const defaultSortModel = [ { field: 'name', sort: 'asc' } ];
const defaultFilterModel = { items: [] };
const defaultColumnVisibilityModel = {
	id: false,
	name: true,
	createdAt: false,
	updatedAt: false
};

interface UserGridProps {
	data: User[],
	setRefresh: Function,
	api: UserApi
}

export const UserGrid: React.FC<UserGridProps> = ( { data, setRefresh, api } ) => {
	const intl = useIntl();
	const newUser = { types: [] };
	const getDate = ( params: any ) => params.value ? new Date( params.value ) : params.value;
	const formatDate = ( params: any ) => {
		if ( !params.value ) {
			return params.value;
		}
		return intl.formatDate( params.value, {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		} );
	};
	PageTitle( { id: 'users', defaultTitle: 'Users' } );
	const [ gridState, setGridState ] = useLocalStorage( 'user-grid-model', {
		columns: { columnVisibilityModel: defaultColumnVisibilityModel },
		sorting: { sortModel: defaultSortModel },
		filtering: { filterModel: defaultFilterModel },
		pinnedColumns: {
			right: [ 'updatedAt', 'actions' ],
			left: []
		}
	} );

	const columns: GridColDef<User>[] = useMemo( () => [
		{ field: 'picture', headerName: 'User', minWidth: 300, description: 'Profile Picture',
			groupable: false,
			renderCell: (params) => {
				const value = params.row;
				return value?.id && <Tooltip key={`${value.id}-title`} title={<ContactCard contact={value} />} placement="top">
					<IconButton
						sx={{ maxHeight: 32, maxWidth: 32 }}
						disabled={!value?.id}
						target="_blank" href={ `https://www.office.com/search/overview/?pp=${ value?.externalId }@${ window.env.msal.tenantId }|${ value?.userPrincipalName ?? value?.email }` }>
						<ProfileImage user={value} sx={{ maxHeight: 32, maxWidth: 32 }}/>
					</IconButton>
				</Tooltip>;
			},
		},
		{
			field: 'name',
			headerName: 'Name',
			minWidth: 300,
			description: 'User Full Name',
			renderCell: ( params ) => (
				<Link id={ `user_name_link${ params.row.id }` } component={ RouterLink }
				      to={ `/admin/iam/users/${ params.row.id }` }>{ params.value }</Link>
			),
			groupable: false
		},
		{
			field: 'email',
			headerName: 'Email',
			minWidth: 80, groupable: true,
			description: 'Email',
		},

		// { field: 'title', headerName: 'Title', minWidth: 300, description: 'Title' },
		// { field: 'department', headerName: 'Department', minWidth: 300, description: 'User Department' },

		{
			field: 'createdAt',
			headerName: 'Created',
			minWidth: 200,
			type: 'dateTime',
			valueFormatter: formatDate,
			valueGetter: getDate
		},
		{
			field: 'updatedAt',
			headerName: 'Updated',
			minWidth: 200,
			type: 'dateTime',
			valueFormatter: formatDate,
			valueGetter: getDate
		},
	], [] );


	function CustomToolbar() {
		const apiRef = useGridApiContext();
		return (
			<GridToolbarContainer>

				<Button
					size="small"
					startIcon={ <Refresh/> }
					onClick={ () => {
						setRefresh( true );
					} }
					title={ intl.formatMessage( { id: 'refresh-grid', defaultMessage: 'Refresh Data' } ) }
				>
					{ intl.formatMessage( { id: 'refresh', defaultMessage: 'Refresh' } ) }
				</Button>
				<GridToolbarColumnsButton/>
				<GridToolbarFilterButton/>
				<GridToolbarExport/>
				<Button
					size="small"
					startIcon={ <Clear/> }
					onClick={ () => {
						apiRef.current.restoreState( {
							...gridState,
							columns: { columnVisibilityModel: defaultColumnVisibilityModel },
							sorting: { sortModel: defaultSortModel },
							filter: { filterModel: defaultFilterModel },
							pinnedColumns: {}
						} );
					} }
					title={ intl.formatMessage( { id: 'reset-grid-config', defaultMessage: 'Reset Grid Settings' } ) }
				>
					{ intl.formatMessage( { id: 'reset', defaultMessage: 'Reset' } ) }
				</Button>
			</GridToolbarContainer>
		);
	}

	return (
		<div style={ { height: '99%', width: '99%', alignContent: 'space-around' } }>

			<DataGridPremium
				rows={ data }
				columns={ columns }
				pagination
				disableRowGrouping={ false }
				checkboxSelection={ false }
				checkboxSelectionVisibleOnly={ false }
				initialState={ gridState }
				onStateChange={ ( state ) => setGridState( state ) }
				editMode="row"
				sortingOrder={ [ 'desc', 'asc' ] }
				slots={ {
					toolbar: CustomToolbar,
					loadingOverlay: LinearProgress,
				} }
				// getDetailPanelContent={({ row })=>{
				// 	return <ContactCard contact={row}/>;
				// }}
			/>
		</div>
	);
};
