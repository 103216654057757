import { IPublicClientApplication } from '@azure/msal-browser';
import { AccountInfo } from '@azure/msal-common/browser';
import { IMsalContext } from '@azure/msal-react';

import ApiProvider from 'services/common/api-provider';
import { getApi } from 'services/common/api-utils';


/**
 * Hook for ApiProvider
 */
export const getApiProvider = async (msalContext: IMsalContext): Promise<ApiProvider> => {

	return getApi(msalContext, {url:''}).then((core)=> {return core.getProvider()})
}
