import { useGridApiRef } from '@mui/x-data-grid-premium';
import get from 'lodash/get';
import React, { useEffect, useMemo, useState } from 'react';
import useIntlPlus from 'components/hooks/IntlPlus';
import {
	Watchlist,
} from '../../components/Watchlist/types';
import useWatchlistPropertiesGridColumns from '../../components/Watchlist/WatchlistPropertiesGridColumns';
import { usePropertyApi } from '../../services/property-api';
import PropertyList from '../../components/Property/PropertyList';
import ErrorDisplay from '../../components/ErrorDisplay';
import useWindowSize from '../../components/WindowSize';
import useLocalStorage from 'react-use-localstorage';
import { useIsAuthenticated } from '@azure/msal-react';
import { useSearchParams } from 'react-router-dom';
import CoreDataGridPage from '../../components/grid/CoreDataGridPage';
import { useWatchlistApi } from '../../services/watchlist-api';

const INITIAL_STATE = {
	'permissions': {
		'read': true,
		'update': true,
		'delete': true
	},
	'id': 24,
	'name': 'My Operational Communities',
	'slug': 'all_communities_0',
	'type': 'property',
	'category': 'My Views',
	'roles': null,
	'state': {
		'aggregation': {
			'model': {
				'market': 'count',
				'bedCount': 'sum',
				'grossArea': 'sum',
				'unitCount': 'sum',
				'retailArea': 'sum',
				'totalValue': 'sum',
				'university': 'join',
				'residentialArea': 'sum',
				'parkingSpaceCount': 'sum'
			}
		},
		'rowGrouping': {
			'model': [
				'phase'
			]
		},
		'pinnedColumns': {
			'left': [
				'__row_group_by_columns_group__'
			],
			'right': [
				'actions'
			]
		},
		'columns': {
			'columnVisibilityModel': {
			},
			'orderedFields': [
				'name',
				'market',
				'propertyGroups',
				'equityPartners',
				'watchlist.state',
				'watchlist.reason',
				'latestStatus.data.unitCount',
				'latestStatus.data.bedCount',
				'latestStatus.data.retailArea',
				'latestStatus.data.cyPreleasePercentages[0][\'CY %\']',
				'latestStatus.data.cyPreleasePercentages[0][\'CY New %\']',
				'latestStatus.data.cyPreleasePercentages[0][\'CY Renewal %\']',
				'latestStatus.data.cyPreleasePercentages[0][\'Delta to PY%\']',
				'latestStatus.data.cyPreleaseNumbers[0][\'CY New\']',
				'latestStatus.data.cyPreleaseNumbers[0][\'CY Leased\']',
				'latestStatus.data.cyPreleaseNumbers[0][\'# Renewals\']',
				'latestStatus.data.cyPreleaseNumbers[0][\'Delta to Comp%\']',
				'latestStatus.data.weeklyLeasingStats1[0][\'Tours\']',
				'latestStatus.data.weeklyLeasingStats1[0][\'Total Leases\']',
				'latestStatus.data.weeklyLeasingStats1[0][\'Total Traffic\']',
				'latestStatus.data.weeklyLeasingStats1[0][\'Conversion Rate\']',
				'latestStatus.data.weeklyLeasingStats2[0][\'Follow-up %\']',
				'latestStatus.data.weeklyLeasingStats2[0][\'Lease End\']',
				'latestStatus.data.weeklyLeasingStats2[0][\'Prior 4 Wk Average\']',
				'latestStatus.data.status',
				'latestStatus.date',
				'latestStatus.state',
				'latestStatus.data.marketInsight',
				'latestStatus.data.marketOutreach',
				'latestStatus.data.residentEvents',
				'latestStatus.data.operationsInsight',
				'latestStatus.data.leasingRecommendation',
				'latestStatus.data.currentConcessions',
				'latestStatus.data.trending[0][\'Trending Effective Rate Growth\']',
				'latestStatus.data.trending[0][\'Budgeted Effective Rate\']',
				'latestStatus.data.trending[0][\'Delta Trending vs. Budgeted\']',
				'latestStatus.data.weeklyMarketShare[0][\'Natural %\']',
				'latestStatus.data.weeklyMarketShare[0][\'Wk Leases\']',
				'latestStatus.data.weeklyMarketShare[0][\'Wk Market %\']',
				'latestStatus.data.weeklyMarketShare[0][\'Delta to Comp %\']',
				'latestStatus.data.teamCommentary',
				'latestStatus.data.mcSupport',
				'latestStatus.data.assetMarketingCommentary',
				'latestStatus.data.rateCommentary',
				'latestStatus.data.rejectionFeedback',
				'latestStatus.data.successes',
				'latestStatus.data.challenges',
				'latestStatus.data.actionItemUpdates',
				'latestStatus.data.newActionItems',
				'createdAt',
				'createdBy',
				'updatedAt',
				'updatedBy',
				'actions'
			],
			'dimensions': {}
		},
		'preferencePanel': {
			'open': false
		},
		'filter': {
			'filterModel': {
				'items': []
			}
		},
		'sorting': {
			'sortModel': [
				{field: '__row_group_by_columns_group__', sort: 'asc'},
				// {field: 'reportingGroup', sort: 'asc'},
				{field: 'name', sort: 'asc'},
			]
		}
	},
	'editable': true,
	'system': false,
};

const DEFAULT_ROW_HEIGHT = 52;
const DEFAULT_WATCHLIST = 1;
const PropertyWatchlist = () => {
	const intl = useIntlPlus();
	const isAuthenticated = useIsAuthenticated();
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [watchlist, setWatchlist] = useState<Watchlist>();
	const handleError = ErrorDisplay();
	const gridApiRef = useGridApiRef();
	const api = usePropertyApi(handleError);
	const watchlistApi = useWatchlistApi(handleError);
	const watchlistPropertyApi = useWatchlistApi( handleError ).watchlistPropertyApi(DEFAULT_WATCHLIST);
	const windowSize = useWindowSize();
	const [view] = useLocalStorage('view','auto');
	const isGrid = view === 'grid' ? true :
		view === 'list' ? false : (windowSize?.width ?? 800) > 600;
	const [searchParams] = useSearchParams();
	const columns = useWatchlistPropertiesGridColumns( { watchlist });
	const fetchData = useMemo(()=>(params:any, config:any)=>{
		const obj:{[key:string]: string|string[]}  = {scope:['watchlist']};
		searchParams.forEach((value, key) => {
			obj[key] = value;
		});
		api.getProperties({params: { ...obj }, ...config})
			.then((data) => {
				setData(data || []);
			});
	},[api, searchParams]);

	const getRowHeight = (params:any) => {
		const lineCounts = gridApiRef?.current?.getVisibleColumns()
			.filter(col=>  col.type === 'string')
			.map((col) =>get( params.model, col.field ))
			.filter((value)=>value && typeof value === 'string' && (value.includes('\n') || value.length > 100))
			.map((value)=>value?.trim().split('\n')?.length >= 3 ? 3 : 2);
		const lineCount = lineCounts.length > 0 ? lineCounts.reduce( ( accumulator, currentValue ) => currentValue > accumulator ? currentValue : accumulator ) : 1;
		const { rowHeight = DEFAULT_ROW_HEIGHT * params.densityFactor} = params;
		const newRowHeight = ( lineCount > 3 ? 3 : lineCount) * 32 * params.densityFactor;
		return newRowHeight > rowHeight ? newRowHeight : rowHeight;
	};

	useEffect(()=>{
		if (isAuthenticated) {
			fetchData(searchParams, {});
			watchlistApi.getOne(DEFAULT_WATCHLIST)
				.then(r=>setWatchlist(r));
		}
	}, [isAuthenticated, searchParams]);

	useEffect(() => {
		if (refresh) {
			setRefresh(false);
			fetchData(searchParams,{ headers: { 'cache-control':'max-age=0' } }); //force cache refresh
		}
	}, [refresh, api, searchParams]);

	const apiAdapter = {
		...api.generic,
		typeSingle: 'Watchlist',
		typePlural: 'Watchlists',
		save: (data:any, config:any)=>{
			data.watchlist.watchlist! = watchlist;
			return api.saveProperty(data, config);
		},
		remove: undefined, // disable this option
		createSchema: undefined,
		updateSchema: undefined,
	};
	return (
		isGrid ?
			<CoreDataGridPage
				title={intl.formatMessage({
					id: 'watchlist',
					defaultMessage: 'Watchlist',
				})}
				data={data}
				setRefresh={setRefresh}
				gridApiRef={gridApiRef}
				api={apiAdapter}
				columns={columns}
				allowEditHiddenFields={true}
				disableGridViewEditing={true}
				disableGridViewSelector={false}
				initialGridView={INITIAL_STATE}
				getRowHeight={ getRowHeight }
				//@ts-ignore
				scope={ 'watchlist' }
				enableMeMode={true}
				columnGroupingModel={[
					{
						groupId: 'internal data',
						children: [{ field: 'id' }],
					},
					{
						groupId: 'character',
						children: [
							{
								groupId: 'naming',
								children: [{ field: 'lastName' }, { field: 'firstName' }],
							},
							{ field: 'age' },
						],
					},
				]}
			/> :
			<PropertyList data={ data } api={ api }/>
	);
};
export default PropertyWatchlist;
