
import { useMsal } from '@azure/msal-react';
import { useAbility } from '@casl/react';

//@ts-ignore
import App from 'base-shell/lib';
import React, {
	useEffect,
	useState
} from 'react';
import { AbilityContext } from './components/AbilityContext';

import _config from './config';
import {
	loginRequest
} from './utils/authConfig';

export const MyApp = () => {

	const [config, setConfig] = useState<any>()
	const ability = useAbility(AbilityContext)
	const msalContext = useMsal();
	const { instance, accounts } = msalContext;

	useEffect(() => {
		console.log('useEffect', { ability })
		if (!ability) {
			return;
		}

		setConfig( _config );
	}, [ ability]);

	useEffect(() => {
		if (!accounts || accounts.length === 0){
			instance.acquireTokenPopup(loginRequest).catch((error)=>{
				console.error('App.tsx unable to login via popup?')
			})
		}
	}, [accounts, instance])

	return (
		<>
		{ config && <App config={ config }/> }
		</>
	);

};
