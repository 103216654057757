import { useMsal } from '@azure/msal-react';
import {
	GridFilterModel,
	GridSortModel
} from '@mui/x-data-grid-premium';
import { useState } from 'react';

import {
	TaskExecution,
	TaskExecutionResults,
	TaskJobSchedule
} from '../components/Admin/SyncJobTypes.js';
import { getApi } from './common/api-utils';

const url = 'admin';
const plural = '/admin';
const single = '/admin';

interface QuerySpec {
	page?: number,
	pageSize?: number,
	sort?: GridSortModel,
	filter?: GridFilterModel
}

interface QueryResults<T> {
	count: number,
	rows: T[]
}

// plural and single may be used for message logic if needed in the ApiCore class.

const apiOptions = {
	getAll: false,
	getSingle: false,
	post: true,
	put: true,
	patch: false,
	delete: false,
	url: url,
	plural: plural,
	single: single
};

export const useAdminApi = ( errorCallback: any ) => {

	const [ isLoading, setIsLoading ] = useState( false );
	const [ hasError, setHasError ] = useState( false );
	const msalContext = useMsal();

	const handleResponse = ( response: any ) => {
		setIsLoading( false );
		console.info('handleResponse')
		return response.data;
	};

	const handleError = ( response: any ) => {
		setIsLoading( false );
		const isError = response.status >= 400;
		setHasError( isError );
		if ( !isError ) {
			return;
		}
		const data = response.data;
		if ( errorCallback ) {
			return errorCallback( data );
		}
		throw data;
	};

	const flushRedis = async ( query: any ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/flush`;
		const api = await getApi( msalContext, apiOptions );
		return api
			.getProvider().post( url, query )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};


	const getAllTasks = async (): Promise<TaskJobSchedule[]> => {
		setIsLoading( true );
		console.info('getAllTasks')
		const url = `${ apiOptions.url }/tasks`;
		const api = await getApi( msalContext, apiOptions );
		return api.getProvider().getAll( url, {} )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	const getTaskHistory = async ( query: QuerySpec ): Promise<QueryResults<TaskExecutionResults>> => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/tasks/history`;
		const api = await getApi( msalContext, apiOptions );
		return api.getProvider().getAll( url, { params: query } )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	const rescheduleAllJobs = async ( query = {} ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/tasks`;
		const api = await getApi( msalContext, apiOptions );
		return api.getProvider().post( url, query )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	const updateJob = async ( row: TaskJobSchedule ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/tasks/${ row.id }`;
		const api = await getApi( msalContext, apiOptions );
		return api.getProvider().put( url, row )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	const runNow = async ( task: TaskExecution, id: number ) => {

		setIsLoading( true );

		const url = `${ apiOptions.url }/tasks/${ id }`;
		const api = await getApi( msalContext, apiOptions );
		return api.getProvider().post( url, task )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};

	const setRoles = async ( query: {} ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/roles`;
		const api = await getApi( msalContext, apiOptions );
		return api.getProvider().post( url, query )
		          .then( handleResponse )
		          .then( response => {
			          return response;
		          } )
		          .catch( handleError );
	};


	return {
		isLoading,
		hasError,
		getAllTasks,
		getTaskHistory,
		rescheduleAllJobs,
		updateJob,
		flushRedis,
		runNow,
		setRoles
	};
};
