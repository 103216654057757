import React, { useEffect, useState } from 'react';
import CardMedia, { CardMediaProps } from '@mui/material/CardMedia';
import { useMediaApi } from '../services/media-api';

interface ProtectedCardMediaProps extends CardMediaProps {
	image: string
}

const ProtectedCardMedia: React.FC<ProtectedCardMediaProps> = ({ image, ...rest }) => {
	const [imageSrc, setImageSrc] = useState<string | null>(null);
	const mediaApi = useMediaApi(()=>undefined);//don't error
	useEffect(() => {
		const fetchImage = async () => {
			const response = await mediaApi.getBlob(image);
			if (response) {
				const blob = new Blob( [ response ], { type: response.type } );
				setImageSrc( URL.createObjectURL( blob ) );
			} else {
				setImageSrc(null);
			}
		};
		image && fetchImage();
	}, [image]);

	return imageSrc ? (
		<CardMedia
			component="img"
			src={imageSrc}
			{ ...rest }
		/>
	) : null;
};

export default ProtectedCardMedia;
