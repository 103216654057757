import { useMsal } from '@azure/msal-react';
import { useState } from 'react';
import { DashboardDownloadRequestConfig } from '../components/Property/PropertyReports.js';
import { Property } from '../components/Property/types.js';
import { ReportState } from '../components/Reports/types.js';
import { getApi } from './common/api-utils';

const url = 'reporting';
const plural = '/reporting';
const single = '/reporting';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiOptions = {
	getAll: false,
	getSingle: true,
	post: true,
	put: true,
	patch: false,
	delete: true,
	url: url,
	plural: plural,
	single: single,
};

export type ReportConfig = {
	name: string;
	type: string;
	version: number
	reportTemplatePath: string;
	reportOutputPath: string;
	config: object;
	createdAt: Date;
	updatedAt: Date;
}

export interface ReportTemplate {
	id: number;
	name: string;
	version?: number;
	type: 'Property' | 'Project';
	config: {
		[key: string]: any
	};
}

export const useReportingApi = ( errorCallback: any ) => {

	const [ isLoading, setIsLoading ] = useState( false );
	const [ hasError, setHasError ] = useState( false );
	const msalContext = useMsal();

	const handleResponse = ( response: any ) => {
		setIsLoading( false );
		return response.data;
	};

	const handleError = ( response: any ) => {
		setIsLoading( false );
		const isError = response.status >= 400;
		setHasError( isError );
		if ( !isError ) {
			return;
		}
		const data = response.data;
		if ( errorCallback ) {
			return errorCallback( data );
		}
		throw data;
	};

	const getLook = async ( id: string, config?: any ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/look`;
		const api = await getApi( msalContext, apiOptions );
		return api.getProvider().getOne( url, id, config )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const renderDashboards = async ( dashboardConfig: DashboardDownloadRequestConfig ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/dashboards`;

		const api = await getApi( msalContext, apiOptions );
		return api.getProvider().getAll( url, { params: { dashboardConfig } } )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const getPropertyBoard = async ( property: Property ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/property-board`;
		const api = await getApi( msalContext, apiOptions );
		return api.getProvider().getOne( url, property.id )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const getBoard = async ( scope: string ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/board`;
		const api = await getApi( msalContext, apiOptions );
		return api.getProvider().getOne( url, scope )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const getReportConfigurations = async ():Promise<ReportConfig[]> => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/config`;
		const api = await getApi( msalContext, apiOptions );
		return api.getProvider().getAll( url )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const getReportConfiguration = async ( name: string ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/config`;
		const api = await getApi( msalContext, apiOptions );
		return api.getProvider().getOne( url, name )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};


	const updateReportConfiguration = async ( reportConfig: ReportConfig ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/config/`;
		console.dir( url );
		const api = await getApi( msalContext, apiOptions );
		return api.getProvider().post( url, reportConfig )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const createReport = async ( spec: ReportState ): Promise<ReportState> => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/create`;
		const api = await getApi( msalContext, apiOptions );
		return api.getProvider().post( url, spec )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const getReportTemplate = async ( type: string, name: string, version?: number, config: any = {} ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/template/${ type }/${ name }`;
		const api = await getApi( msalContext, apiOptions );
		return api.getProvider().getOne( url, version, config )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const getReport = async ( id: number, config: any = {} ) => {
		setIsLoading( true );
		const api = await getApi( msalContext, apiOptions );
		return api.getSingle( id, config )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const saveReport = async ( report: any, config?: any ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/`;
		const api = await getApi( msalContext, apiOptions );
		return api.getProvider().post( url, report, config )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const refreshReport = async ( report: any, config: any = {} ) => {
		return saveReport( report, { ...config, params: { ...config?.params, action: 'refresh' } } );
	};

	const submitReport = async ( report: any, config: any = {} ) => {
		return saveReport( report, { ...config, params: { ...config?.params, action: 'submit' } } );
	};

	const retractReport = async ( report: any, config: any = {} ) => {
		return saveReport( report, { ...config, params: { ...config?.params, action: 'retract' } } );
	};

	const publishReport = async ( report: any, config: any = {} ) => {
		return saveReport( report, { ...config, params: { ...config?.params, action: 'publish' } } );
	};

	const unPublishReport = async ( report: any, config: any = {} ) => {
		setIsLoading( true );
		report.published = '';
		return saveReport( report, { ...config, params: { ...config?.params, action: 'unpublish' } } );
	};


	const deleteReport = async ( report: any, config?: any ) => {
		setIsLoading( true );
		const api = await getApi( msalContext, apiOptions );
		return api.delete( report.id, config )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const getReports = async ( config?: any ) => {
		setIsLoading( true );
		const url = `${ apiOptions.url }/`;
		const api = await getApi( msalContext, apiOptions );
		return api.getProvider().getAll( url, config )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const getBudgetRateStatus = async ( params?: string ) => {
		const url = `${ apiOptions.url }/budget-rate-status?${ params }`;
		console.log( url );
		const api = await getApi( msalContext, apiOptions );
		return api.getProvider().getAll( url )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	return {
		isLoading,
		hasError,
		getLook,
		renderDashboards,
		getReportConfiguration,
		getReportConfigurations,
		updateReportConfiguration,
		getPropertyBoard,
		getBoard,
		createReport,
		getReportTemplate,
		getReports,
		getReport,
		refreshReport,
		saveReport,
		submitReport,
		retractReport,
		publishReport,
		unPublishReport,
		deleteReport,
		getBudgetRateStatus,
	};
};
