import {lazy} from 'react';
import locales from './locales';
import routes from './routes';
import themes from './themes';
import parseLanguages from 'base-shell/lib/utils/locale';
import Loading from '../components/Loading/Loading';

const config = {
	containers: {
		LayoutContainer: lazy(() =>
			import('material-ui-shell/lib/containers/LayoutContainer/LayoutContainer')
		),
	},
	components: {
		Loading,
		Menu: lazy(() => import('material-ui-shell/lib/containers/Menu/Menu')),
	},
	// isAuthenticated: {
	// 	signInURL: '/signin',
	// },
	pwa: {
		useiOSPWAPrompt: true,
		iOSPWAPromptProps: {},
	},
	routes,
	locale: {
		locales,
		defaultLocale: parseLanguages(['en']),
		onError: (e) => {
			// Here we warn the user about translation error
			//console.warn(e)
		},
	},
	menu: {
		width: 240,
		offlineIndicatorHeight: 12,
		initialAuthMenuOpen: true,
		initialMiniMode: false,
		initialMenuOpen: true,
		initialMobileMenuOpen: false,
		initialMiniSwitchVisibility: true,
		MenuHeader: lazy(() =>
			import('components/MenuHeader')
		),
		MenuContent: lazy(() => import('components/Menu/MenuContent')),
		useWindowWatcher: false,
	},
	theme: {
		themes,
		defaultThemeID: 'default',
		defaultIsDarkMode: false,
		defaultIsRTL: false, //change this to true for default Right to Left Language support
	},
	pages: {
		LandingPage: lazy(() => import('../pages/LandingPage/LandingPage')),
		PageNotFound: lazy(() => import('../pages/PageNotFound/PageNotFound')),
	},
};

export default config;
