import {
	GridColDef,
	GridLogicOperator,
	GridState,
	useGridApiRef
} from '@mui/x-data-grid-premium';
import React, {
	useEffect,
	useMemo,
	useState
} from 'react';
import ErrorDisplay from '../../components/ErrorDisplay';
import PageTitle from '../../components/hooks/PageTitle';
import {
	ReportConfig,
	useReportingApi
} from '../../services/reporting-ui-api';
import CoreDataGrid from '../grid/CoreDataGrid';
import useGridHelper from '../hooks/GridHelper';

type ReportConfigProps = {

}

const initialGridState: Partial<GridState> = {
	columns: {
		lookup: {},
		orderedFields: [],
		columnVisibilityModel: {
			actions: true,
			name: true,
		},
	},
	//columnGroupingModel: {},
	pinnedColumns: {
		left: [ 'name' ],
		right: [
			'actions'
		]
	},
	rowGrouping: {
		model: [ ]
	},
	filter: {
		filterModel: {
			items: [],
			//linkOperator: '',
			quickFilterValues: [],
			quickFilterLogicOperator: GridLogicOperator.And
		},
		//visibleRowsLookup: {},
		filteredDescendantCountLookup: {},
		filteredRowsLookup: {}
	},
	sorting: {
		sortedRows: [],
		sortModel: [
			{ field: 'name', sort: 'asc' }
		]
	}
};

const ReportConfiguration: React.FC<ReportConfigProps> = () => {
	const [ rows, setRows ] = useState<ReportConfig[]>([]);
	const handleError = ErrorDisplay();
	const api = useReportingApi( handleError );
	PageTitle( { id: 'report.admin', defaultTitle: 'Report Configuration' } );
	const [ open, setOpen ] = React.useState( false );
	const { formatDate, getDate } = useGridHelper();
	const [ refresh, setRefresh ] = useState( true );
	const gridApiRef = useGridApiRef();

	useEffect( () => {
		api.getReportConfigurations(  ).then( ( rows: ReportConfig[] ) => setRows( rows ) );
	}, [] );

	const columns: GridColDef<ReportConfig>[] = useMemo( () => [
		// {
		// 	field: 'actions', type: 'actions', maxWidth: 20, minWidth: 20, width: 20,
		// 	getActions: ( params: any ) => [
		// 		<GridActionsCellItem icon={ <EditIcon/> } onClick={ editRow( params ) } label="Edit" />,
		// 	]
		// },
		{
			field: 'name', headerName: 'Name', description: 'Name used to reference template config in code', groupable: false,
			editable: true, readOnly: true
		},
		{
			field: 'reportTemplatePath',
			headerName: 'Report Template',
			description: 'Report Template Path.  Can be internal or external (sharepoint)',
			editable: true
		},
		{
			field: 'reportOutputPath', headerName: 'Output Path', minWidth: 80, editable: true, groupable: true,
			description: 'Report output path',
		},
		{ field: 'type', headerName: 'Type', editable: false },
		{ field: 'version', headerName: 'Version', editable: false },
		{ field: 'config', headerName: 'Configuration', editable: true, type:'json',
			renderCell: (params)=> JSON.stringify(params.value, null, 2),
		},
		{
			field: 'createdAt',
			headerName: 'Created',
			minWidth: 200,
			type: 'dateTime',
			valueFormatter: formatDate,
			valueGetter: getDate
		},
		{
			field: 'updatedAt',
			headerName: 'Updated',
			minWidth: 200,
			type: 'dateTime',
			valueFormatter: formatDate,
			valueGetter: getDate
		},
	], [] );

	return (
		<CoreDataGrid
			data={ rows }
			columns={ columns }
			gridApiRef={ gridApiRef }
			setRefresh={ setRefresh }
			disableGridViewEditing={true}
			disableGridViewSelector={true}
			defaultGridSettings={ {
				editable: true,
				state: initialGridState,
			} }
			api={ {
				typeSingle: 'ReportTemplate',
				typePlural: 'ReportTemplates',
				isLoading: api.isLoading,
				hasError: api.hasError,
				getAll: api.getReportConfigurations,
				save: api.updateReportConfiguration
			} }
		/>
	);
};

export default ReportConfiguration;
