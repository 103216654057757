import {
	IDynamicPerson,
	PeoplePicker
} from '@microsoft/mgt-react';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import 'styles/mgt.css';
import React, { useState } from 'react';
import { Contact } from './User/types';

export type MsGraphSearchProps = {
	id: string;
	onChange: ( event: CustomEvent<IDynamicPerson[]>, value: Contact, reason: string ) => Promise<void>|void;
	label?: string;
	value?: Contact;
	disableSuggestions?: boolean
}

const MsGraphSearch: React.FC<MsGraphSearchProps> = ( props ) => {
	const [value, setValue] = useState<Contact|undefined>(props.value);
	const { disableSuggestions = true } = props;
	const onChange = props.onChange;
	const handleChange = async ( event: CustomEvent<IDynamicPerson[]> ) => {
		console.log( 'MsGraphSearch.onChange', { event } );
		const selected: MicrosoftGraph.User = event.detail[0] as MicrosoftGraph.User; //FIXME the picker does support different types
		console.log( 'MsGraphSearch.onChange: selected', { selected } );
		if ( selected ) {
			const contact: Contact = {
				externalId: selected.id!,
				name: selected.displayName!,
				email: selected.mail ?? selected.userPrincipalName ?? 'unknown',
				title: selected.jobTitle!
			};
			setValue( contact );
			await onChange( event, contact, 'selected contact' );
		}
	};

	return (
		<div style={ { width: '100%', alignContent: 'space-around' } }>
			<PeoplePicker
				selectionMode="single"
				defaultSelectedUserIds={ [] }
				selectionChanged={ handleChange }
				defaultValue={ value?.externalId }
				disableSuggestions = { disableSuggestions }
			/>
		</div>
	);

};


export default MsGraphSearch;
