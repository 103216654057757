import { useMsal } from '@azure/msal-react';
import { useState } from 'react';
import { getApi } from './common/api-utils';

export type MediaApi = {
	isLoading: boolean,
	hasError: boolean,
	getBlob: ( name: string ) =>Promise<any>,
}

const url = '/media';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiOptions = {
	getAll: false,
	getSingle: false,
	post: true,
	put: false,
	patch: false,
	delete: false,
	url: url,
};

export const useMediaApi = ( errorCallback?: any ): MediaApi => {

	const [ isLoading, setIsLoading ] = useState( false );
	const [ hasError, setHasError ] = useState( false );
	const msalContext = useMsal();

	const handleResponse = ( response: any ) => {
		setIsLoading( false );
		return response.data;
	};

	const handleError = ( response: any ) => {
		setIsLoading( false );
		const isError = response.status >= 400;
		setHasError( isError );
		if ( !isError ) {
			return;
		}
		if ( errorCallback ) {
			return errorCallback( {message: response.statusText} );
		}
		throw response;
	};

	const getBlob = async ( name: string ) => {
		setIsLoading( true );
		const api = await getApi( msalContext, apiOptions );
		name = name?.replace( /^\//, '' );
		return await api.getProvider().getOne( url, name, { responseType: 'blob' } )
			.then( handleResponse )
			.then( response => {
				return new Blob([response], { type: response.type });
			})
			.catch( handleError );
	};

	return {
		isLoading,
		hasError,
		getBlob,
	};
};
