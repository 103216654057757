import {
	Person,
	PersonCard
} from '@microsoft/mgt-react';
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ProfileImage from 'components/ProfileImage';
import { Link, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import { Email, OpenInNew, VideoCall } from '@mui/icons-material';
import { Contact } from './User/types';

interface ContactCardProps {
  contact?: Contact;
  contactId?: number
}

const StyledCard = styled(Card)(({ theme }) => ({
	// @ts-ignore
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	// @ts-ignore
	...theme.typography.body2,
	// @ts-ignore
	color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.text.secondary,
}));

const ContactCard: React.FC<ContactCardProps> = ({ contact }) => {
	return (
		contact?.externalId ?
		<PersonCard
			userId={ contact?.externalId! }

		/> :
		<StyledCard elevation={3}>
			<CardContent>
				<Stack direction="row" spacing={1}>
					{ contact &&
						<ProfileImage
							alt={contact?.name}
							user={contact}
							sx={{ width: 64, height: 64 }}
						/>}
					<Stack>
						<Typography variant="subtitle1" component="div">
							{contact?.name}
						</Typography>
						<Typography variant="caption" component="div">
							{contact?.email}
						</Typography>
						<Box marginTop="auto" width="100%" alignContent='center'>
							{contact?.externalId && <Link
								href={ `https://www.office.com/search/overview/?pp=${ contact.externalId }@${ window.env.msal.tenantId }|${ contact.userPrincipalName ?? contact.email }` }
								target="_blank"
								rel="noopener noreferrer"
								underline="none"
								title="View Contact"
							>
								<OpenInNew />
							</Link>}
							<Link
								href={`mailto:${contact?.email}`}
								target="_blank"
								rel="noopener noreferrer"
								underline="none"
								title={`Email ${contact?.name}`}
							>
								<Email />
							</Link>
							<Link
								href={`https://teams.microsoft.com/l/chat/0/0?users=${contact?.email}`}
								target="_blank"
								rel="noopener noreferrer"
								underline="none"
								title='Chat via Teams'
							>
								<VideoCall />
							</Link>
						</Box>
					</Stack>

				</Stack>
			</CardContent>
		</StyledCard>
	);
};

export default ContactCard;
