import { InteractionStatus } from '@azure/msal-browser';
import {
	useIsAuthenticated,
	useMsal
} from '@azure/msal-react';
import AdminToolbar from 'components/Admin/AdminToolbar';
import { User } from 'components/User/types';
import { UserGrid } from 'components/User/UserGrid';

import Page from 'material-ui-shell/lib/containers/Page';

import React, {
	useEffect,
	useState
} from 'react';
import { useIntl } from 'react-intl';
import { useUserApi } from 'services/user-api';

const IAMUsers = () => {
	const intl = useIntl();
	const isAuthenticated = useIsAuthenticated();
	const { inProgress } = useMsal();
	const [ data, setData ] = useState<User[]>( [] );
	const [ refresh, setRefresh ] = useState( true );

	const api = useUserApi();

	useEffect( () => {
		if ( refresh && isAuthenticated && inProgress === InteractionStatus.None ) {
			setRefresh( false );
			api.getAll()
			   .then( ( data ) => {
				   setData( data || [] );
			   } );
		}
	}, [ refresh, api, isAuthenticated ] );

	return (
		<Page
			pageTitle={ intl.formatMessage( { id: 'users', defaultMessage: 'Users' } ) }
			isLoading={ api.isLoading }
			appBarContent={
				<AdminToolbar/>
			}
		>
			<UserGrid data={ data } setRefresh={ setRefresh } api={ api }/> );
		</Page> );
};
export default IAMUsers;
