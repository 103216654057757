import { useMsal } from '@azure/msal-react';
import { useState } from 'react';
import { getApi } from './common/api-utils';

const url = 'audit';
const plural = '/audit';
const single = '/audit';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiOptions = {
	getAll: true,
	getSingle: true,
	post: true,
	put: true,
	patch: false,
	delete: false,
	url: url,
	plural: plural,
	single: single,
};

export const useAuditApi = (errorCallback) => {

	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const msalContext = useMsal();

	const handleResponse = response => {
		setIsLoading(false);
		return response.data;
	};

	const handleError = response => {
		setIsLoading(false);
		const isError = response.status >= 400;
		setHasError(isError);
		if (!isError) {
			return;
		}
		const data = response.data;
		if (errorCallback) {
			return errorCallback(data);
		}
		throw data;
	};

	const findAll = async (query) => {
		setIsLoading(true);
		const api = await getApi( msalContext, apiOptions );
		return api.getAll(query)
			.then(handleResponse)
			.catch(handleError);
	};

	const getActivityFeed = async (query) => {
		setIsLoading(true);
		const api = await getApi( msalContext, apiOptions );
		return api.apiProvider
			.getAll(`${ apiOptions.url }/feed`, query)
			.then(handleResponse)
			.catch(handleError);
	};

	const getTopUsers = async (query) => {
		setIsLoading(true);
		return api.apiProvider
			.getAll(`${ apiOptions.url }/top-users`, query)
			.then(handleResponse)
			.catch(handleError);
	};

	const findByTypeId = async (type, typeId) => {
		setIsLoading(true);
		const api = await getApi( msalContext, apiOptions );
		return api.apiProvider.getAll(`${ apiOptions.url }/${ type }/${ typeId }`)
			.then(handleResponse)
			.catch(handleError);
	};

	const capture = async (params) => {
		setIsLoading(true);
		const api = await getApi( msalContext, apiOptions );
		return api.post(params)
			.then(handleResponse)
			.catch(handleError);
	};

	const getRestrictedFieldValue = async ({typeName, typeId, fieldName}) => {
		setIsLoading(true);
		const api = await getApi( msalContext, apiOptions );
		return api.apiProvider.getOne(`${ apiOptions.url }/${ typeName }/${ typeId }`, fieldName)
			.then(handleResponse)
			.catch(handleError);
	};

	const save = async (audit) => {
		if (audit.id) {
			const api = await getApi( msalContext, apiOptions );
			return api.put(audit)
				.then(handleResponse)
				.then(response => {
					return response;
				})
				.catch(handleError);
		}
		const api = await getApi( msalContext, apiOptions );
		return api.post(audit)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	return {
		isLoading,
		hasError,
		findAll,
		findByTypeId,
		getActivityFeed,
		getTopUsers,
		capture,
		getRestrictedFieldValue,
		save,
	};
};
